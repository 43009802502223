
import Vue from "vue";
import Component from "vue-class-component";
import MarketList from "@/components/MarketList";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { Routes } from "@/router/routes";
import { isUserInRoles } from "@/services/userUtils";
import { DxTextBox } from "devextreme-vue/text-box";
import BaseButton from "@/components/BaseButton";
import DynamicTitle from "@/components/DynamicTitle";
import { DxNumberBox } from "devextreme-vue/number-box";
import { MarketGetters } from "@/store/modules/market/getters";
import { Market } from "@/models/market/Market";

@Component({
    components: {
        BaseButton,
        MarketList,
        DynamicTitle,
        DxTextBox,
        DxNumberBox
    },
    methods: {
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets,
            exportMarkets: MarketActions.ExportMarkets
        })
    },
    computed: {
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    }
})
export default class Markets extends Vue {
    private readonly loadMarkets!: () => Promise<void>;
    protected readonly exportMarkets!: () => Promise<void>;
    private readonly markets!: Market[];

    protected filterValue = "";

    mounted(): void {
        this.loadMarkets();
    }

    addNewMarket(): void {
        this.$router.push(Routes.CreateMarket);
    }

    get isAdminButtonsVisible(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    get numberOfMarket(): number {
        if (this.filterValue) {
            return this.markets.filter((m) =>
                m.name.toLowerCase().includes(this.filterValue.toLowerCase())
            ).length;
        }
        return this.markets.length;
    }
}
