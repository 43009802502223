
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import AppIcon from "@/components/AppIcon/AppIcon.vue";
import { Attachment } from "@/models/Attachment";
import notify from "devextreme/ui/notify";
import { fileTypes } from "@/consts/fileTypes";

@Component({
    components: { DxFileUploader, AppIcon }
})
export default class FileUploader extends Vue {
    @Prop({ type: String, default: "Attachments" })
    public readonly label!: string;

    @Prop({ type: Array, default: () => [] })
    public readonly attachments!: Attachment[];

    @Prop({ type: Boolean, default: false })
    public readonly readonly!: boolean;

    @Prop({ type: Number, default: null })
    public readonly minHeight!: number | null;

    protected files: File[] = [];

    protected readonly maxFileSize = 30 * 1000 * 1000; // in bytes => 30MB

    // The server will check if the type matches the extension, because browsers can't determine file types themselves
    uploadFile(file: File): void {
        const fileExtension = "." + file.name.split(".").pop();
        const fileType = fileTypes[fileExtension];
        if (!fileType || !fileType.includes(file.type)) {
            notify(
                `File type '${fileExtension}' is not allowed.`,
                "warning",
                3000
            );
            return;
        }
        if (file.size < this.maxFileSize) {
            this.files.push(file);
            this.$emit("add-new-file", file);
        } else {
            notify("The maximum file size allowed is 30MB", "warning", 3000);
        }
    }

    removeNewFile(file: File): void {
        this.files = this.files.filter((f) => f !== file);
        this.$emit("remove-new-file", file);
    }

    removeAttachment(attachmentId: number): void {
        this.$emit("remove-attachment", attachmentId);
    }

    @Watch("attachments")
    updateAttachments(newAttachments: Attachment[]): void {
        this.files = [];
        newAttachments.forEach((attachment) => {
            this.files = this.files.filter(
                (f) => f.name !== attachment.fileName
            );
        });
    }
}
