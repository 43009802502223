export const fileTypes: { [key: string]: string[] } = {
    ".doc": ["application/msword"],
    ".txt": ["text/plain"],
    ".docm": ["application/vnd.ms-word.document.macroEnabled.12"],
    ".docx": [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ],
    ".xls": ["application/vnd.ms-excel"],
    ".xlsx": [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ],
    ".xlsm": ["application/vnd.ms-excel.sheet.macroEnabled.12"],
    ".ppt": ["application/vnd.ms-powerpoint"],
    ".pdf": ["application/pdf"],
    ".png": ["image/png"],
    ".jpg": ["image/jpeg"],
    ".jpeg": ["image/jpeg"],
    ".gif": ["image/gif"],
    ".bmp": ["image/bmp"],
    ".tiff": ["image/tiff"],
    ".csv": ["text/csv", "text/x-csv", "text/plain", "application/vnd.ms-excel"]
};
