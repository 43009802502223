export function leaveConfirmation(show: boolean, next: () => void): void {
    if (show) {
        const confirm = window.confirm(
            "There are unsaved changes, are you sure to leave this page"
        );
        if (confirm) {
            next();
        }
    } else {
        next();
    }
}
