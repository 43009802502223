
import Vue from "vue";
import Component from "vue-class-component";
import { MarketDetails } from "@/models/market/MarketDetails";
import { Routes } from "@/router/routes";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import { DxList } from "devextreme-vue/list";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { UserActions } from "@/store/modules/user/actions";
import { User } from "@/models/User";
import { getUserTitle } from "@/services/userUtils";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import DxAccordion from "devextreme-vue/accordion";
import MarketEditor from "@/components/MarketEditor";
import DataSource from "devextreme/data/data_source";
import DynamicTitle from "@/components/DynamicTitle";
import DxButton from "devextreme-vue/button";
import FixedPanel from "@/components/FixedPanel";
import {
    formatValidationErrors,
    isValidationError
} from "@/services/responseErrorUtils";
import notify from "devextreme/ui/notify";

@Component({
    components: {
        DxLoadPanel,
        DxList,
        DxAccordion,
        DxButton,
        MarketEditor,
        DynamicTitle,
        FixedPanel
    },
    computed: {
        ...marketModule.mapGetters({
            marketDetails: MarketGetters.MarketDetails,
            isLoading: MarketGetters.IsLoading,
            isEdited: MarketGetters.IsEdited,
            errors: MarketGetters.Errors
        }),
        ...userModule.mapGetters({
            users: UserGetters.Users
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadMarketDetails: MarketActions.LoadMarketDetails,
            updateMarket: MarketActions.UpdateMarket
        }),
        ...userModule.mapActions({
            loadUsersByMarketId: UserActions.LoadUsersByMarket
        })
    }
})
export default class EditMarket extends Vue {
    private readonly loadMarketDetails!: (marketId: number) => Promise<void>;
    private readonly loadUsersByMarketId!: (marketId: number) => Promise<void>;
    private readonly updateMarket!: () => Promise<void>;

    private readonly marketDetails!: MarketDetails | null;
    private readonly users!: User[] | null;
    protected readonly isLoading!: boolean;
    protected readonly isEdited!: boolean;
    protected readonly errors!: string[] | null;

    protected titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.Markets },
        { name: "Edit Market", selected: true }
    ];

    protected loadingPosition = { of: "#marketEditor" };
    protected userAccordion = ["Assigned users"];

    protected marketName: string | null = null;

    private leaveConfirmationEnabled = true;

    created(): void {
        if (this.marketId !== null) {
            this.loadMarketDetails(this.marketId).then(() => {
                this.marketName = this.marketDetails?.name ?? null;
            });
            this.loadUsersByMarketId(this.marketId);
        } else {
            this.$router.push(Routes.Markets);
        }

        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    saveMarket(): void {
        this.updateMarket().then(() => {
            if (this.errors?.length) {
                this.showErrors(this.errors);
                return;
            }

            this.leaveConfirmationEnabled = false;
            this.$router.push(Routes.Markets);
        });
    }

    showUserItem(user: User): string {
        return getUserTitle(user, this.marketId ?? -1);
    }

    get marketId(): number | null {
        const id = this.$router.currentRoute.params.id;
        const parsedId = Number(id);
        return isNaN(parsedId) ? null : parsedId;
    }

    get userDataSource(): DataSource | null {
        if (this.users?.length) {
            return new DataSource({
                store: this.users,
                paginate: true,
                pageSize: 5
            });
        }
        return null;
    }

    showErrors(errors: string[]): void {
        if (isValidationError(errors[0])) {
            notify(formatValidationErrors(errors), "error", 3000);
        } else {
            notify(errors.join(", "), "error", 3000);
        }
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
