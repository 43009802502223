
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { Routes } from "@/router/routes";
import { marketModule } from "@/store/modules/market";
import MarketEditor from "@/components/MarketEditor";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import DynamicTitle from "@/components/DynamicTitle";
import FixedPanel from "@/components/FixedPanel";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import {
    isValidationError,
    formatValidationErrors
} from "@/services/responseErrorUtils";
import notify from "devextreme/ui/notify";

@Component({
    components: { DxButton, MarketEditor, DynamicTitle, FixedPanel },
    computed: {
        ...marketModule.mapGetters({
            isLoading: MarketGetters.IsLoading,
            isEdited: MarketGetters.IsEdited,
            errors: MarketGetters.Errors
        })
    },
    methods: {
        ...marketModule.mapActions({
            addNewMarket: MarketActions.AddNewMarket,
            createMarket: MarketActions.CreateMarket
        })
    }
})
export default class CreateMarket extends Vue {
    private readonly addNewMarket!: () => Promise<void>;
    private readonly createMarket!: () => Promise<void>;

    protected readonly isLoading!: boolean;
    protected readonly isEdited!: boolean;
    protected readonly errors!: string[] | null;

    protected titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.Markets },
        { name: "Create New Market", selected: true }
    ];

    private leaveConfirmationEnabled = true;

    created(): void {
        this.addNewMarket();
        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    backClicked(): void {
        this.$router.push(Routes.Markets);
    }

    submit(): void {
        this.createMarket().then(() => {
            if (this.errors?.length) {
                this.showErrors(this.errors);
                return;
            }

            this.leaveConfirmationEnabled = false;
            this.$router.push(Routes.Markets);
        });
    }

    showErrors(errors: string[]): void {
        if (isValidationError(errors[0])) {
            notify(formatValidationErrors(errors), "error", 3000);
        } else {
            notify(errors.join(", "), "error", 3000);
        }
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
