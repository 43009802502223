
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TitleElement } from "./TitleElement";
import AppIcon from "@/components/AppIcon";

@Component({
    components: { AppIcon }
})
export default class DynamicTitle extends Vue {
    @Prop({ type: Array, required: true })
    public elements!: TitleElement[];

    @Prop({ type: String, required: true })
    public title!: string;

    titleElementClick(name: string): void {
        this.$emit("click", name);
    }
}
