
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Market } from "@/models/market/Market";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { DxButton } from "devextreme-vue/button";
import { DxList } from "devextreme-vue/list";
import { MarketActions } from "@/store/modules/market/actions";
import { Routes } from "@/router/routes";
import DataSource from "devextreme/data/data_source";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import BaseButton from "@/components/BaseButton";

@Component({
    components: { DxButton, DxList, ConfirmationDialog, BaseButton },
    computed: {
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    },
    methods: {
        ...marketModule.mapActions({
            deleteMarket: MarketActions.DeleteMarket
        })
    }
})
export default class MarketList extends Vue {
    private readonly deleteMarket!: (marketId: number) => Promise<void>;

    @Prop({ type: Boolean, default: false })
    public readonly searchEnabled!: boolean;

    @Prop({ type: String, default: "" })
    public readonly filterValue!: string;

    protected readonly markets!: Market[];

    protected deleteDialog = false;
    protected selectedItem: Market | null = null;

    get filteredMarkets(): Market[] {
        if (this.filterValue) {
            return this.markets.filter((m) =>
                m.name.toLowerCase().includes(this.filterValue.toLowerCase())
            );
        }
        return this.markets;
    }

    get dataSource(): DataSource | null {
        if (this.filteredMarkets) {
            return new DataSource({
                store: this.filteredMarkets,
                paginate: true,
                pageSize: 20
            });
        }
        return null;
    }

    showDeleteDialog(market: Market): void {
        this.selectedItem = market;
        this.deleteDialog = true;
    }

    deleteSelectedMarket(): void {
        if (this.selectedItem) {
            this.deleteMarket(this.selectedItem.marketId);
        }
    }

    editMarket(market: Market): void {
        this.$router.push(Routes.EditMarket + market.marketId);
    }
}
