
import Vue from "vue";
import Component from "vue-class-component";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxValidator, {
    DxRequiredRule,
    DxStringLengthRule
} from "devextreme-vue/validator";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxNumberBox } from "devextreme-vue/number-box";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { MarketDetails } from "@/models/market/MarketDetails";
import { MarketMutations } from "@/store/modules/market/mutations";
import { DxTagBox } from "devextreme-vue/tag-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import { MarketInfo } from "@/models/market/MarketInfo";
import { Attachment } from "@/models/Attachment";
import FileUploader from "@/components/FileUploader";
import { isUserInRoles } from "@/services/userUtils";

@Component({
    components: {
        DxTextBox,
        DxSelectBox,
        DxTextArea,
        DxNumberBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxTagBox,
        DxCheckBox,
        FileUploader
    },
    computed: {
        ...marketModule.mapGetters({
            marketDetails: MarketGetters.MarketDetails,
            marketInfo: MarketGetters.MarketsInfo
        })
    },
    methods: {
        ...marketModule.mapMutations({
            updateMarketDetails: MarketMutations.UpdateMarketDetails,
            addAttachment: MarketMutations.AddAttachment,
            removeAttachment: MarketMutations.RemoveAttachment
        })
    }
})
export default class MarketEditor extends Vue {
    private readonly updateMarketDetails!: (
        payload: Partial<MarketDetails>
    ) => void;
    protected readonly addAttachment!: (file: File) => void;
    protected readonly removeAttachment!: (fileName: string) => void;

    private readonly marketDetails!: MarketDetails | null;

    protected readonly marketInfo!: MarketInfo;

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    get name(): string {
        return this.marketDetails?.name ?? "";
    }
    set name(value: string) {
        this.updateMarketDetails({ name: value });
    }

    get type(): number | null {
        if (this.marketDetails?.marketTypeId)
            return this.marketDetails.marketTypeId;
        return null;
    }
    set type(value: number | null) {
        if (value) {
            this.updateMarketDetails({ marketTypeId: value });
        }
    }

    get region(): number | null {
        if (this.marketDetails?.regionId) return this.marketDetails.regionId;
        return null;
    }
    set region(value: number | null) {
        if (value) {
            this.updateMarketDetails({ regionId: value });
        }
    }

    get VAT(): string | undefined {
        return this.marketDetails?.vat;
    }
    set VAT(value: string | undefined) {
        this.updateMarketDetails({ vat: value });
    }

    get VATComment(): string | undefined {
        return this.marketDetails?.vatComment;
    }
    set VATComment(value: string | undefined) {
        this.updateMarketDetails({ vatComment: value });
    }

    get currency(): string | undefined {
        return this.marketDetails?.currency;
    }
    set currency(value: string | undefined) {
        this.updateMarketDetails({ currency: value });
    }

    get BMWDealers(): number | undefined {
        return this.marketDetails?.bmwDealers;
    }
    set BMWDealers(value: number | undefined) {
        this.updateMarketDetails({ bmwDealers: value });
    }

    get miniDealers(): number | undefined {
        return this.marketDetails?.miniDealers;
    }
    set miniDealers(value: number | undefined) {
        this.updateMarketDetails({ miniDealers: value });
    }

    get servicePoints(): number | undefined {
        return this.marketDetails?.servicePoints;
    }
    set servicePoints(value: number | undefined) {
        this.updateMarketDetails({ servicePoints: value });
    }

    get warrantyPeriod(): number | undefined {
        return this.marketDetails?.warrantyPeriod;
    }
    set warrantyPeriod(value: number | undefined) {
        this.updateMarketDetails({ warrantyPeriod: value });
    }

    get customerSupport(): string | undefined {
        return this.marketDetails?.customerSupport;
    }
    set customerSupport(value: string | undefined) {
        this.updateMarketDetails({ customerSupport: value });
    }

    get additionalInformation(): string | undefined {
        return this.marketDetails?.additionalMarketInformation;
    }
    set additionalInformation(value: string | undefined) {
        this.updateMarketDetails({ additionalMarketInformation: value });
    }

    get selectedCountryCodes(): string[] {
        return this.marketDetails?.countryCodes ?? [];
    }
    set selectedCountryCodes(value: string[]) {
        this.updateMarketDetails({ countryCodes: value });
    }

    get countryPackNumber(): string | undefined {
        return this.marketDetails?.countryPackNumber;
    }
    set countryPackNumber(value: string | undefined) {
        this.updateMarketDetails({ countryPackNumber: value });
    }

    get reminderEnabled(): boolean {
        return this.marketDetails?.reminderEnabled ?? false;
    }
    set reminderEnabled(value: boolean) {
        this.updateMarketDetails({ reminderEnabled: value });
    }

    get attachments(): Attachment[] {
        return this.marketDetails?.attachments ?? [];
    }
}
